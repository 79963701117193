import React from "react";
function DisclaimerScreen() {
  return (
    <div>
      <div className="row m-auto">
        <div className="col-12">
          <h2 className="">Disclaimer for Books Mitra</h2>
        </div>
      </div>
      <section className="disclaimer">
        <div className="p-2 content">
          <p>
            All the information on this website is published in good faith and
            for general information purpose only.
            <i>booksmitra.in</i> does not make any warranties about the
            completeness, reliability and accuracy of this information. Any
            action you take upon the information you find on this website (
            <i>booksmitra.in</i>), is strictly at your own risk.{" "}
            <i>booksmitra.in</i> will not be liable for any losses and/or
            damages in connection with the use of our website.
          </p>
          <p>
            From our website, you can visit other websites by following
            hyperlinks to such external sites. While we strive to provide only
            quality links to useful and ethical websites, we have no control
            over the content and nature of these sites. These links to other
            websites do not imply a recommendation for all the content found on
            these sites. Site owners and content may change without notice and
            may occur before we have the opportunity to remove a link which may
            have gone ‘bad’. Source for few images is Internet. There might be
            some delay in delivering the items due to technical issues from our
            side or from the vendors with whom we ship the products. Kindly
            Contact us if you have any concerns over it.
          </p>
          <p>
            Please be also aware that when you leave our website, other sites
            may have different privacy policies and terms which are beyond our
            control. Please be sure to check the Privacy Policies of these sites
            as well as their “Terms of Service” before engaging in any business
            or uploading any information.
          </p>
          <p>
            <strong>Consent</strong>
          </p>
          <p>
            By using our website, you hereby consent to our disclaimer and agree
            to its terms.
          </p>
          <p>
            <strong>Update</strong>
          </p>
          <p>
            Should we update, amend or make any changes to this document, those
            changes will be prominently posted here.
          </p>
          <p>
            If you require any more information or have any questions about our
            site’s disclaimer, please feel free to contact us by email at
            <i> booksmitra@gmail.com</i>.
          </p>
          <p>Last updated on October 15 2021</p>
        </div>
      </section>
    </div>
  );
}

export default DisclaimerScreen;
