import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { contact } from "../../actions/userActions";
import FormContainer from "../../components/FormContainer";
import Loader from "../../components/Loader";
import Message from "../../components/Message";

function ContactScreen({ history }) {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userContact = useSelector((state) => state.userContact);
  const { error: errorContact, loading: loadingContact, message } = userContact;

  useEffect(() => {
    if (message != null) {
      console.log("message:", message);
      history.push("/");
    }
  }, [history, message]);

  const [name, setName] = useState(userInfo ? userInfo.name : "");
  const [email, setEmail] = useState(userInfo ? userInfo.email : "");
  const [mobile, setMobile] = useState(userInfo ? userInfo.mobile : "");
  const [text, setText] = useState("");
  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(contact(name, mobile, email, text));
  };

  return (
    <FormContainer>
      <h1>Contact Us</h1>
      {errorContact && <Message variant="danger">{errorContact}</Message>}
      {message && <Message variant="success">{message}</Message>}
      {loadingContact && <Loader />}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="name">
          <Form.Label>Your Name</Form.Label>
          <Form.Control
            required maxLength='30'
            type="text"
            placeholder="Enter full name"
            value={name ? name : ""}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="mobile">
          <Form.Label>Mobile number</Form.Label>
          <Form.Control
            required
            type="text" maxLength='10'
            placeholder="Enter mobile number"
            value={mobile ? mobile : ""}
            onChange={(e) => setMobile(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Email" maxLength='30'
            value={email ? email : ""}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="text">
          <Form.Label>Comment</Form.Label>
          <Form.Control
            required
            type="textArea" maxLength='300'
            placeholder="Enter your query"
            value={text}
            onChange={(e) => setText(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <div className="text-center m-2">
          <Button type="submit" variant="primary">
            Submit
          </Button>
        </div>
      </Form>
    </FormContainer>
  );
}

export default ContactScreen;
