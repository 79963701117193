import axios from "axios";
import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_SHIPPING_ADDRESS,
} from "../constants/cartConstants";
import { ORDER_PAY_RESET } from "../constants/orderConstants";

export const addToCart = (id, qty) => async (dispatch, getState) => {
  const { data } = await axios.get(`/api/books/${id}`);
  dispatch({
    type: ORDER_PAY_RESET
  });

  dispatch({
    type: CART_ADD_ITEM,
    payload: {
      book: data.book.id,
      name: data.book.name,
      original_price: data.book.original_price,
      discounted_price: data.book.discounted_price,
      delivery_charges: data.book.delivery_charges,
      stocks_left: data.book.stocks_left,
      weight_in_grams: data.book.weight_in_grams,
      primary_image:data.book.images[0],
      qty,
    },
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

export const removeFromCart = (id) => (dispatch, getState) => {
  console.log('remove from cart action:', dispatch, getState)
  dispatch({
    type: ORDER_PAY_RESET
  });

  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id,
  });
  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

export const saveShippingAddress = (data) => (dispatch) => {
  console.log("shipping address data in cart action:", data);
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: data,
  });

  localStorage.setItem("shippingAddress", JSON.stringify(data));
};
