import axios from "axios";
import {
  BOOK_CREATE_REVIEW_FAIL,
  BOOK_CREATE_REVIEW_REQUEST,
  BOOK_CREATE_REVIEW_SUCCESS,
  BOOK_DETAILS_FAIL,
  BOOK_DETAILS_REQUEST,
  BOOK_DETAILS_SUCCESS,
  BOOK_FEATURED_FAIL,
  BOOK_FEATURED_REQUEST,
  BOOK_FEATURED_SUCCESS,
  BOOK_LIST_FAIL,
  BOOK_LIST_REQUEST,
  BOOK_LIST_SUCCESS,
  BOOK_NOTIFY_ME_FAIL,
  BOOK_NOTIFY_ME_REQUEST,
  BOOK_NOTIFY_ME_SUCCESS,
} from "../constants/bookConstants";

export const listBooks =
  (keyword = "", page = 1, filter_args={}) =>
  async (dispatch) => {
    try {
      dispatch({ type: BOOK_LIST_REQUEST });
      let url = "/api/books";
      console.log("List books keyword::", keyword);
      filter_args['keyword'] = keyword;
      filter_args['page'] = page
      console.log("Filter args:", filter_args, url);
      const { data } = await axios(url, {params: filter_args});
      dispatch({
        type: BOOK_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: BOOK_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const listFeaturedBooks = () => async (dispatch) => {
  try {
    dispatch({ type: BOOK_FEATURED_REQUEST });

    const { data } = await axios.get(`/api/featured/`);

    dispatch({
      type: BOOK_FEATURED_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BOOK_FEATURED_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listBookDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: BOOK_DETAILS_REQUEST });
    const { data } = await axios(`/api/books/${id}`);
    dispatch({
      type: BOOK_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BOOK_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createBookReview =
  (bookId, review) => async (dispatch, getState) => {
    try {
      dispatch({
        type: BOOK_CREATE_REVIEW_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/books/${bookId}/reviews/`,
        review,
        config
      );
      dispatch({
        type: BOOK_CREATE_REVIEW_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: BOOK_CREATE_REVIEW_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const bookNotifyMeAction = (bookId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BOOK_NOTIFY_ME_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/books/${bookId}/notify-me/`, config);
    dispatch({
      type: BOOK_NOTIFY_ME_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BOOK_NOTIFY_ME_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
