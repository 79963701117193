import React, { useEffect } from 'react'
import { Carousel, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { listFeaturedBooks } from '../actions/bookActions'
import Loader from './Loader'
import Message from './Message'

function FeaturedCarousel() {
  const dispatch = useDispatch();

  const bookFeatured = useSelector((state) => state.bookFeatured);
  const { error, loading, books } = bookFeatured;
  useEffect(() => {
    dispatch(listFeaturedBooks());
  }, [dispatch]);

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <Carousel pause="hover" interval={4000} className="bg-dark">
      {books.map((book) => (
        <Carousel.Item key={book.id}>
          <Link to={{ pathname: book.link}} target="_blank">
            <Image src={book.image} alt={book.name} fluid />
            <Carousel.Caption className="carousel.caption">
              <h4>{book.name}</h4>
            </Carousel.Caption>
          </Link>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default FeaturedCarousel;
