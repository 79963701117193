import {
  BOOK_CREATE_REVIEW_FAIL,
  BOOK_CREATE_REVIEW_REQUEST,
  BOOK_CREATE_REVIEW_RESET,
  BOOK_CREATE_REVIEW_SUCCESS,
  BOOK_DETAILS_FAIL,
  BOOK_DETAILS_REQUEST,
  BOOK_DETAILS_SUCCESS,
  BOOK_FEATURED_FAIL,
  BOOK_FEATURED_REQUEST,
  BOOK_FEATURED_SUCCESS,
  BOOK_LIST_FAIL,
  BOOK_LIST_REQUEST,
  BOOK_LIST_SUCCESS,
  BOOK_NOTIFY_ME_FAIL,
  BOOK_NOTIFY_ME_REQUEST,
  BOOK_NOTIFY_ME_RESET,
  BOOK_NOTIFY_ME_SUCCESS,
} from "../constants/bookConstants";
export const bookListReducer = (state = { books: [] }, action) => {
  switch (action.type) {
    case BOOK_LIST_REQUEST:
      return { loading: true, books: state.books };
    case BOOK_LIST_SUCCESS:
      let context = null;
      if (action.payload.page === 1) {
        context = {
          loading: false,
          books: action.payload.books, 
          page: action.payload.page,
          pages: action.payload.pages,
        };
      } else {
        context = {
          ...state,
          loading: false,
          books: state.books.concat(action.payload.books), //  [...state.books, action.payload.books],
          page: action.payload.page,
          pages: action.payload.pages,
        };
      }
      return context;
    case BOOK_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const bookDetailsReducer = (
  state = { book: { reviews: [], images: [], related_books: [] } },
  action
) => {
  switch (action.type) {
    case BOOK_DETAILS_REQUEST:
      return { loading: true, ...state };
    case BOOK_DETAILS_SUCCESS:
      return {
        loading: false,
        book: action.payload.book,
        next_book:action.payload.next_book,
        prev_book:action.payload.prev_book
      };
    case BOOK_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const bookReviewCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case BOOK_CREATE_REVIEW_REQUEST:
      return { loading: true };

    case BOOK_CREATE_REVIEW_SUCCESS:
      return { loading: false, success: true };

    case BOOK_CREATE_REVIEW_FAIL:
      return { loading: false, error: action.payload };

    case BOOK_CREATE_REVIEW_RESET:
      return {};

    default:
      return state;
  }
};

export const bookFeaturedReducer = (state = { books: [] }, action) => {
  switch (action.type) {
    case BOOK_FEATURED_REQUEST:
      return { loading: true, books: [] };

    case BOOK_FEATURED_SUCCESS:
      return { loading: false, books: action.payload };

    case BOOK_FEATURED_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const bookNotifyMeReducer = (state = {}, action) => {
  switch (action.type) {
    case BOOK_NOTIFY_ME_REQUEST:
      return { loading: true };

    case BOOK_NOTIFY_ME_SUCCESS:
      return { loading: false, success: true, data: action.payload };

    case BOOK_NOTIFY_ME_FAIL:
      return { loading: false, error: action.payload };

    case BOOK_NOTIFY_ME_RESET:
      return {};

    default:
      return state;
  }
};
