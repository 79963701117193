import React from "react";
function AboutScreen() {
  return (
    <div>
      <h1> About BooksMitra</h1>
      <p>
        Welcome to <strong> BooksMitra </strong> (From KPSCVaani), It has
        started in 2017 by Halli Technologies with the moto of serving multiple
        categories of books with best possible prices to government job
        aspirants, School students, book lovers. We have evolved from KPSCVaani
        to Books mitra with your continous support and now came up with improved
        version, Better performance and speed. We are primarily located in
        Bagalkot district and having a branch at Bangalore.
      </p>
      <p>
        BooksMitra can offer you All kinds of Competitive Books, School books,
        Motivations books at a very reasonable prices in our Platform. We now
        serve customers all over India, and are thrilled that we're able to turn
        our passion into website.
      </p>
      <p>
        <a target="_blank" rel="noreferrer" href="https://www.booksmitra.in">
          <i>BooksMitra </i>
        </a>{" "}
        website's main feature is to deliver books to all the conrners of India
        with good service. BooksMitra works continuously and passionately to
        help all students/aspirants making them brilliant in their studies and
        achieving the goals. It is bringing many resources and details together
        for all the aspirants who are preparing for competitive exams. We hope
        you enjoy our products as much as we enjoy offering them to you.
      </p>
      <p>
        If you have any questions or comments or wish to provide us some
        feedback, please don't hesitate to contact us at{" "}
        <i> booksmitra@gmail.com</i>.
      </p>

      <p>
        Sincerely, BooksMitra team <br />
        (Halli Technologies)
      </p>
    </div>
  );
}

export default AboutScreen;
