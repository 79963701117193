import React from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Rating from "./Rating";
function Book({ book }) {
  let discount = 0;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  if (book != null) {
    discount = Math.ceil(
      ((book.original_price - book.discounted_price) / book.original_price) *
        100
    );
  }
  const truncate = (name) => {
    return name.length > 45 ? name.substring(0, 42) + "..." : name;
  };
  return (
    <Card className="my-1 p-1 rounded bookCard">
      {/* Load only one image - */}
      <Link to={`/books/${book.id}/${book.slug}`}>
        {book.images && book.images.length ? (
          <Card.Img
            className="bookImage rounded"
            src={book.images && book.images.length && book.images[0].thumbnail}
          />
        ) : (
          <Card.Img
            className="bookImage rounded"
            src={book.primary_image && book.primary_image.thumbnail}  
            // Image for related books
          />
        )}
      </Link>

      <Card.Body className="p-0 mt-1">
        <Link to={`/books/${book.id}/${book.slug}`}>
          <Card.Title as="div" className="bookTitle">
            <strong>{truncate(book.name)} </strong>
          </Card.Title>
        </Link>
        <Card.Text as="div">
          <div className="my-3">
            {book.reviews_count > 0 ? (
              <Rating
                value={book.average_review_rating}
                text={`(${book.reviews_count})`}
                color={"#f8e825"}
              />
            ) : (
              ""
            )}
          </div>
        </Card.Text>
        {book.stocks_left === 0 ? (
          <Card.Text as="div">
            <div className="product-image-center mb-1 border rounded text-white py-2 px-1 bg-info">
              <span>
                {" "}
                {book.out_of_stock_text
                  ? book.out_of_stock_text
                  : "Out of Stock"}
              </span>
            </div>
          </Card.Text>
        ) : (
          ""
        )}
        {userInfo && userInfo.isAdmin && (
          <button className="m-0">Stocks left: {book.stocks_left}</button>
        )}
        <Card.Text as="h4" className="mt-1 text-fast-red">
          &#8377;{""}
          <strong>{book.discounted_price} </strong>
          {book.original_price > book.discounted_price ? (
            <small className="text-muted font-size-medium">
              <del>&#8377;{book.original_price}</del>
            </small>
          ) : (
            ""
          )}
          {discount > 0 ? (
            <span className="text-success ">
              <strong> {discount}% off </strong>
            </span>
          ) : (
            ""
          )}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
export default Book;
