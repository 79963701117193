import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import {
  bookDetailsReducer,
  bookFeaturedReducer,
  bookListReducer,
  bookNotifyMeReducer,
  bookReviewCreateReducer,
} from "./reducers/bookReducers";
import { cartReducer } from "./reducers/cartReducers";
import {
  orderConfirmReducer,
  orderCreateReducer,
  orderDetailsReducer,
  orderListMyReducer,
  orderPayReducer,
  trackOrderReducer,
} from "./reducers/orderReducers";
import {
  forgotPasswordReducer,
  resetPasswordReducer,
  userContactReducer,
  userDetailsReducer,
  userLoginReducer,
  userRegisterReducer,
  userUpdateProfileReducer,
} from "./reducers/userReducers";

const reducer = combineReducers({
  bookList: bookListReducer,
  bookDetails: bookDetailsReducer,
  bookReviewCreate: bookReviewCreateReducer,
  bookNotifyMe: bookNotifyMeReducer,
  bookFeatured: bookFeaturedReducer,

  cart: cartReducer,

  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,

  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderListMy: orderListMyReducer,
  orderConfirm: orderConfirmReducer,
  orderPay: orderPayReducer,
  userContact: userContactReducer,
  forgotPassword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer,
  trackOrder: trackOrderReducer,
});

const cartItemsFromStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const shippingAddressFromStorage = localStorage.getItem("shippingAddress")
  ? JSON.parse(localStorage.getItem("shippingAddress"))
  : {};

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    shippingAddress: shippingAddressFromStorage,
  },
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
