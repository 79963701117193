import React from "react";
function PrivacyScreen() {
  return (
    <div>
      <div className="row m-auto">
        <div className="col-md-12">
          <h2 className="">booksmitra.in Privacy Policy</h2>
        </div>
      </div>
      <section className="disclaimer">
        <div className="content p-2">
          <p>
            <strong>
              What personal information do we collect from the people that visit
              our blog, website or app?
            </strong>
          </p>
          <p>
            When registering or updating content on our site, as appropriate,
            you may be asked to enter your name, email address, phone number or
            other details to help you with your experience.
          </p>
          <p>
            {" "}
            <strong> When do we collect information? </strong>{" "}
          </p>
          <p>
            We collect information from you when you fill out a form or enter
            information on our site.
          </p>
          <p>
            {" "}
            <strong> How do we use your information? </strong>
          </p>
          <p>
            We may use the information we collect from you when you update
            content for our newsletter, respond to a survey or marketing
            communication, surf the website, or use certain other site features
            in the following ways:
          </p>
          <ul>
            <li>
              To allow us to better service you in responding to your customer
              service requests.
            </li>
            <li>
              To administer a contest, promotion, survey or other site feature.
            </li>
          </ul>

          <p>
            {" "}
            <strong>How do we protect your information?</strong>
          </p>
          <p>
            We only provide articles and information. We never ask for credit
            card numbers or other sensitive information. We do not use Malware
            Scanning. In case any personal information which is shared with us
            during registration or while booking(whether mandatorily or
            optionally), eventhough we guarantee that your data is safe with us,
            we will not be liable for any information security breach or
            disclosure in relation to such information.
          </p>
          <p>
            {" "}
            <strong> Do we use 'cookies'? </strong>
          </p>
          <p>
            We do not use cookies for tracking purposes You can choose to have
            your computer warn you each time a cookie is being sent, or you can
            choose to turn off all cookies. You do this through your browser
            settings. Since browser is a little different, look at your
            browser's Help Menu to learn the correct way to modify your cookies.
            If you turn cookies off, Some of the features that make your site
            experience more efficient may not function properly.that make your
            site experience more efficient and may not function properly.
          </p>
          <p>
            {" "}
            <strong> Third-party disclosure</strong>
          </p>
          <p>
            We do not sell, trade, or otherwise transfer to outside parties your
            Personally Information.
          </p>
          <p>
            {" "}
            <strong> Third-party links</strong>
          </p>
          <p>
            Occasionally, at our discretion, we may include or offer third-party
            products or services on our website. These third-party sites have
            separate and independent privacy policies. We therefore have no
            responsibility or liability for the content and activities of these
            linked sites. Nonetheless, we seek to protect the integrity of our
            site and welcome any feedback about these sites.
          </p>
          <p>
            Users can visit our site anonymously. Our Privacy Policy link
            includes the word 'Privacy' and can easily be found on the all
            pages.
          </p>
          <p>
            You will be notified of any Privacy Policy changes: On our Privacy
            Policy Page
          </p>
          <p>
            {" "}
            <strong>How does our site handle Do Not Track signals?</strong>
          </p>
          <p>
            We honor Do Not Track signals and Do Not Track, plant cookies, or
            use advertising when a Do Not Track (DNT) browser mechanism is in
            place.
          </p>
          <p>
            {" "}
            <strong>
              Does our site allow third-party behavioral tracking?
            </strong>
          </p>
          <p>
            It's also important to note that we do not allow third-party
            behavioral tracking
          </p>
          <p>
            All rights reserved. This site or any portion thereof may not be
            reproduced or used in any manner whatsoever without the express
            written permission of the website.
          </p>
          <p>
            {" "}
            <strong> Contacting Us</strong>
          </p>
          <p>
            If there are any questions regarding this privacy policy, you may
            contact us using the information below.
          </p>
          <ul>
            <li><i> booksmitra@gmail.com </i></li>
            <li>
              <a
                className="site-cta-link"
                href="tel:+91-86602-55805"
                data-tracking-element-type="3"
                itemprop="telephone"
                dir="ltr"
              >
                +91 86602 55805
              </a>
            </li>
          </ul>
          <p>Last updated on 15-10-2021</p>
        </div>
      </section>
    </div>
  );
}

export default PrivacyScreen;
