import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { listBooks } from "../actions/bookActions";
import Book from "../components/Book";
import FeaturedCarousel from "../components/FeaturedCarousel";
import FilterBooks from "../components/FilterBooks";
import Loader from "../components/Loader";
import Message from "../components/Message";


function HomeScreen({ history }) {
  const dispatch = useDispatch();
  const bookList = useSelector((state) => state.bookList);
  const { error, loading, books, page, pages } = bookList;

  let keyword = history.location.search;

  useEffect(() => {
    // Compute scroll percentage and trigger load books
    if (books && books.length !== 0) {
      const getNextBooks = () => {
        let search_keyword = "";
        // if (keyword) {
        //   search_keyword = keyword.split("?keyword=")[1].split("&")[0];
        // }
        if (page && page < pages) {
          const next_page = page + 1;
          dispatch(listBooks("", next_page));
        }
      };

      function getScrollPercent() {
        var h = document.documentElement,
          b = document.body,
          st = "scrollTop",
          sh = "scrollHeight";
        return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
      }
      let timer;
      window.onscroll = (e) => {
        clearTimeout(timer);
        const scrollPercentage = getScrollPercent();
        timer = setTimeout(function () {
          timer = null;
          if (scrollPercentage > 30) {
            getNextBooks();
          }
        }, 500);
      };
    } else if (page) {
      console.log("No data exists..");
    } else {
      dispatch(listBooks(keyword));
    }
  }, [dispatch, keyword, page, pages, books]);
  return (
    <div className="bg-light">
      {!keyword && <FeaturedCarousel />}

      {/* <button onClick={handleAppend}>Append Data</button> */}

      {error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <div>
          <FilterBooks />
          <h1 className="mt-1 p-0"> Latest Books </h1>
          <Row className="m-auto">
            {books &&
              books.map((book) => (
                <Col
                  className="px-1 my-1"
                  key={book.id}
                  xs={6}
                  sm={4}
                  lg={3}
                  xl={2}
                >
                  <Book book={book} />
                </Col>
              ))}
            {books && page && books.length === 0 && (
              <div className="alert alert-danger text-center">
                No books found, update your search..!
              </div>
            )}
            {page && page === pages && (
              <div className="alert alert-warning text-center">
                <a className="btn btn-info" href="/contact">
                  Didn't find the Book you're looking for?
                </a>
              </div>
            )}
          </Row>
          {/* <Paginate page={page} pages={pages} keyword={keyword} /> */}
        </div>
      )}
      {loading && <Loader />}
    </div>
  );
}

export default HomeScreen;
