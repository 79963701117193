import React from "react";
import { Col, Nav, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
function Footer() {
  const current_year = new Date().getFullYear();

  return (
    <footer className='bg-light'>
      <div className="p-0 text-center">
        <Row className='m-auto'>
          <Col>
            <LinkContainer to="/contact">
              <Nav.Link>Contact Us</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/about">
              <Nav.Link>About Us</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/privacy">
              <Nav.Link>Privacy</Nav.Link>
            </LinkContainer>
          </Col>
          <Col>
            <LinkContainer to="/terms-and-conditions">
              <Nav.Link>Terms and Conditions</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/disclaimer">
              <Nav.Link>Disclaimer</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/returns-and-refunds">
              <Nav.Link>Returns and Refunds</Nav.Link>
            </LinkContainer>
          </Col>
        </Row>
        <Row className='m-auto'>
          <Col className="text-center">
            Copyright &copy; {current_year} Halli Technologies
          </Col>
        </Row>
      </div>
    </footer>
  );
}

export default Footer;
