import React from "react";
function TermsAndCondScreen() {
  return (
    <div>
      <h4>Terms and conditions</h4>
      <p>
        These terms and conditions (&quot;Terms&quot;, &quot;Agreement&quot;)
        are an agreement between Website Operator (&quot;Website Operator&quot;,
        &quot;us&quot;, &quot;we&quot; or &quot;our&quot;) and you
        (&quot;User&quot;, &quot;you&quot; or &quot;your&quot;). This Agreement
        sets forth the general terms and conditions of your use of the{" "}
        <a target="_blank" rel="noreferrer" href="https://www.booksmitra.in">
          booksmitra.in{" "}
        </a>{" "}
        website and any of its products or services (collectively,
        &quot;Website&quot; or &quot;Services&quot;).
      </p>
      <h5>Accounts and membership</h5>
      <p>
        If you create an account on the Website, you are responsible for
        maintaining the security of your account and you are fully responsible
        for all activities that occur under the account and any other actions
        taken in connection with it. We may, but have no obligation to, monitor
        and review new accounts before you may sign in and use our Services.
        Providing false contact information of any kind may result in the
        termination of your account. You must immediately notify us of any
        unauthorized uses of your account or any other breaches of security. We
        will not be liable for any acts or omissions by you, including any
        damages of any kind incurred as a result of such acts or omissions.
      </p>
      <p>
        We may suspend, disable, or delete your account (or any part thereof) if
        we determine that you have violated any provision of this Agreement or
        that your conduct or content would tend to damage our reputation and
        goodwill. If we delete your account for the foregoing reasons, you may
        not re-register for our Services. We may block your email address and
        Internet protocol address to prevent further registration.
      </p>
      <h5>User content</h5>
      <p>
        We do not own any data, information or material (&quot;Content&quot;)
        that you submit on the Website in the course of using the Service. You
        shall have sole responsibility for the accuracy, quality, integrity,
        legality, reliability, appropriateness, and intellectual property
        ownership or right to use of all submitted Content. We may monitor and
        review Content on the Website submitted or created using our Services by
        you. Unless specifically permitted by you, your use of the Website does
        not grant us the license to use, reproduce, adapt, modify, publish or
        distribute the Content created by you or stored in your user account for
        commercial, marketing or any similar purpose. But you grant us
        permission to access, copy, distribute, store, transmit, reformat,
        display and perform the Content of your user account solely as required
        for the purpose of providing the Services to you. Without limiting any
        of those representations or warranties, we have the right, though not
        the obligation, to, in our own sole discretion, refuse or remove any
        Content that, in our reasonable opinion, violates any of our policies or
        is in any way harmful or objectionable.
      </p>
      <h5>Backups</h5>
      <p>
        We perform regular backups of the Website and Content and will do our
        best to ensure completeness and accuracy of these backups. In the event
        of the hardware failure or data loss we will restore backups
        automatically to minimize the impact and downtime.
      </p>
      <h5>Links to other websites</h5>
      <p>
        Although this Website may link to other websites, we are not, directly
        or indirectly, implying any approval, association, sponsorship,
        endorsement, or affiliation with any linked website, unless specifically
        stated herein. Some of the links on the Website may be &quot;affiliate
        links&quot;. This means if you click on the link and purchase an item,
        Website Operator will receive an affiliate commission. We are not
        responsible for examining or evaluating, and we do not warrant the
        offerings of, any businesses or individuals or the content of their
        websites. We do not assume any responsibility or liability for the
        actions, products, services, and content of any other third-parties. You
        should carefully review the legal statements and other conditions of use
        of any website which you access through a link from this Website. Your
        linking to any other off-site websites is at your own risk.
      </p>
      <h5>Changes and amendments</h5>
      <p>
        We reserve the right to modify this Agreement or its policies relating
        to the Website or Services at any time, effective upon posting of an
        updated version of this Agreement on the Website. When we do, we will
        revise the updated date at the bottom of this page. Continued use of the
        Website after any such changes shall constitute your consent to such
        changes.
      </p>
      <h5>Acceptance of these terms</h5>
      <p>
        You acknowledge that you have read this Agreement and agree to all its
        terms and conditions. By using the Website or its Services you agree to
        be bound by this Agreement. If you do not agree to abide by the terms of
        this Agreement, you are not authorized to use or access the Website and
        its Services.
      </p>
      <h5>Contacting us</h5>
      <p>
        If you would like to contact us to understand more about this Agreement
        or wish to contact us concerning any matter relating to it, you may do
        so via the{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.booksmitra.in/contact"
        >
          contact form
        </a>{" "}
        or send an email to <i> booksmitra@gmail.com </i>
      </p>
      <p>This document was last updated on October 15, 2020</p>
    </div>
  );
}

export default TermsAndCondScreen;
